// extracted by mini-css-extract-plugin
export var dropdownContainer = "training-topic-module--dropdownContainer--HLRUQ";
export var leftColumn = "training-topic-module--leftColumn--O5+ef";
export var questionContainer = "training-topic-module--questionContainer--GU+eL";
export var rightColumn = "training-topic-module--rightColumn--T5+cD";
export var topicIcon = "training-topic-module--topicIcon--OcMfi";
export var topicItem = "training-topic-module--topicItem--JAr9u";
export var topicItemActive = "training-topic-module--topicItem--active--8GcH-";
export var topicItemContainer = "training-topic-module--topicItemContainer--s+Cqm";
export var topicName = "training-topic-module--topicName--GUwbI";
export var topicsHeader = "training-topic-module--topicsHeader--7Q+5K";
export var videoContainer = "training-topic-module--videoContainer--N90-y";